import { useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Route } from '../types/routes';

export const useSearchNavigate = () => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const navigateWithSearchParams = useCallback(
    (pathname: Route) => navigate({ pathname, search }),
    [navigate, search],
  );

  return navigateWithSearchParams;
};
