/*  */
import React, { createContext, useContext, useState, FC, useCallback } from 'react';
import gameService from '../../services/game';
import useGameContext from '../GameContext';
import { useLanguage } from '../LanguageContext';
import { ContextProps, Leaderboard, ProviderProps } from './types';
export const LeaderboardContext = createContext<ContextProps>({
  leaderboard: [],
  fetchTop9: async () => {},
  fetchCurrentSessionLeaderboard: async () => {},
  isLoading: false,
});

export const LeaderboardProvider: FC<ProviderProps> = ({ children }: ProviderProps) => {
  const [leaderboard, setLeaderboard] = useState<Leaderboard[]>([]);
  const [isLoading, setLoading] = useState(false);
  const { gameSession } = useGameContext();
  const { languageSelector } = useLanguage();

  const fetchTop9 = useCallback(async () => {
    try {
      setLoading(true);
      const res = await gameService.getTop9Leaderboard(languageSelector);
      setLeaderboard(res.data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchCurrentSessionLeaderboard = async () => {
    try {
      setLoading(true);
      const res = await gameService.getSessionLeaderboard(gameSession?.id || 0, languageSelector);
      setLeaderboard(res.data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <LeaderboardContext.Provider
      value={{
        leaderboard,
        fetchTop9,
        fetchCurrentSessionLeaderboard,
        isLoading,
      }}>
      {children}
    </LeaderboardContext.Provider>
  );
};

export default function useLeaderboardContext(): ContextProps {
  return useContext(LeaderboardContext);
}
