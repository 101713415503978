import { useCallback } from 'react';
// import useGameContext from '../../context/GameContext';
import { useLanguage } from '../../context/LanguageContext';
import { useContainerDimensions } from '../../hooks/useContainerDimensions';
import { useDeviceDetector } from '../../hooks/useDeviceDetector';
import { ButtonContainer } from '../Button';
import { Logo } from '../Logo';
import { Button, FooterContainer, LeftContent } from './styles';
import { getWidth } from '../../utils/ui';

type FooterPropTypes = {
  onEndGame: (() => void) | undefined;
};

export function Footer({ onEndGame }: FooterPropTypes) {
  const { language } = useLanguage();
  // const { setTermsModalOpen } = useGameContext();
  const { isMobile } = useDeviceDetector();
  const containerDimensions = useContainerDimensions();
  const getValue = useCallback(
    (value: number): number => {
      return getWidth({
        containerDimensions,
        isMobile,
        value,
      });
    },
    [containerDimensions, isMobile],
  );
  return (
    <FooterContainer>
      <LeftContent>
        <Logo height={39} width={61} />
      </LeftContent>
      <div className={onEndGame ? 'buttons-wrapper' : 'terms_and_conditions'}>
        {/* <ButtonContainer style={{ width: `${getValue(220)}px` }}>
          <Button
            className="interstate"
            onClick={() => {
              setTermsModalOpen(true);
            }}>
            {language.terms_and_conditions}
          </Button>
        </ButtonContainer> */}
        {onEndGame && (
          <ButtonContainer style={{ width: `${getValue(100)}px` }}>
            <Button className="interstate" onClick={onEndGame}>
              {language.end_game}
            </Button>
          </ButtonContainer>
        )}
      </div>
    </FooterContainer>
  );
}
