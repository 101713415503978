import styled, { css } from 'styled-components';
import { ButtonElem as BaseButton } from '../../components/Button';
import homeBackground from '../../assets/backgrounds/home.webp';
import homeVerticalBackground from '../../assets/backgrounds/home-vertical.webp';
import { onVerticalScreen } from '../../utils/css';

export const HomeContainer = styled.main`
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  height: 100%;
  overflow: hidden;
  gap: ${({ theme }) => `${theme.getValue(86)}`};
  background: url(${homeBackground}) no-repeat 85% 70%, #53c3fe;
  background-size: auto 120%;
  background-repeat: no-repeat;
  background-position: 85% 70%;

  img {
    width: ${({ theme }) => `${theme.getValue(161)}`};
    height: ${({ theme }) => `${theme.getValue(104)}`};
  }

  & > div:first-of-type {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  h1,
  h2 {
    margin: 0;
    color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.fonts.citiSans};
    font-weight: 400;
  }

  h1 {
    margin: ${({ theme }) => `${theme.getValue(37)}`} 0;
    font-size: ${({ theme }) => `${theme.getFontSize(50)}`};
  }

  h2 {
    margin-bottom: ${({ theme }) => `${theme.getValue(64)}`};
    font-size: ${({ theme }) => `${theme.getFontSize(30)}`};
    line-height: ${({ theme }) => `${theme.getFontSize(30)}`};
  }

  ${onVerticalScreen(css`
    background: url(${homeVerticalBackground}), #53c3fe;
    background-repeat: no-repeat;
    background-position: 45% 80%;
    background-size: 120% auto;
    flex-direction: column;
    gap: ${({ theme }) => `${theme.getValue(130)}`};

    h1 {
      margin: ${({ theme }) => `${theme.getValue(64)}`} 0;
      font-size: ${({ theme }) => `${theme.getFontSize(74)}`};
    }

    h2 {
      margin-bottom: ${({ theme }) => `${theme.getValue(104)}`};
      font-size: ${({ theme }) => `${theme.getFontSize(50)}`};
      line-height: ${({ theme }) => `${theme.getFontSize(30)}`};
    }

    img {
      width: ${({ theme }) => `${theme.getValue(260)}`};
      height: ${({ theme }) => `${theme.getValue(168)}`};
    }
  `)}
`;

export const LinkButton = styled(BaseButton)`
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  text-decoration: none;
  margin: 0;
  border: none;
  min-width: ${({ theme }) => `${theme.getValue(293)}`};
  text-align: center;

  ${({ theme }) =>
    onVerticalScreen(css`
      padding: ${`${theme.getValue(32)} ${theme.getValue(64)}`};
      min-width: ${`${theme.getValue(500)}`};
    `)}
`;

export const LeftContent = styled.div`
  width: ${({ theme }) => `${theme.getValue(425)}`};
  ${onVerticalScreen(css`
    width: ${({ theme }) => `${theme.getValue(600)}`};
  `)}
`;
