import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Leaderboard } from '../../components/Leaderboard';
import { Logo } from '../../components/Logo';
import { useLanguage } from '../../context/LanguageContext';
import useLeaderboardContext from '../../context/LeaderboardContext';
import usePlayerContext from '../../context/PlayerContext';
import { Route as AppRoute } from '../../types/routes.d';
import { LinkButton, HomeContainer, LeftContent } from './styles';
import { ButtonContainer } from '../../components/Button';

export function Home() {
  const { search } = useLocation();
  const { leaderboard, fetchTop9 } = useLeaderboardContext();
  const { setPlayer } = usePlayerContext();
  const [mounted, setMounted] = useState(false);
  const [pressed, setPressed] = useState(false);
  const [pressedIn, setPressedIn] = useState(false);
  const { language } = useLanguage();
  const navigate = useNavigate();

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    setPlayer(undefined);
    if (mounted) fetchTop9();
  }, [mounted]);

  return (
    <HomeContainer>
      <LeftContent>
        <Logo />

        <h1>Citi's New Way</h1>
        <h2>{language.speed_quiz}</h2>

        <ButtonContainer>
          <LinkButton
            className={pressed ? (pressedIn ? 'pressed' : 'notpressed') : ''}
            onClick={() => {
              setPressed(true);
              setPressedIn(true);
              setTimeout(() => {
                setPressedIn(false);
              }, 500);
              setTimeout(() => {
                navigate(AppRoute.Registration + search);
              }, 1000);
            }}>
            {language.begin_game}
          </LinkButton>
        </ButtonContainer>
      </LeftContent>

      <div>
        <Leaderboard
          borderless
          results={leaderboard.map((session) => ({
            participantName: session.playerName,
            score: session.points,
            position: session.position + 1,
          }))}
        />
      </div>
    </HomeContainer>
  );
}
