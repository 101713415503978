import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  background-color: black;
  video {
    border: none;
    outline: none;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
`;
