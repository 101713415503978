import { useCallback, useEffect, useState } from 'react';
import { Container, Option } from './styles';

const UPPER_CASE_A_ASCII_CODE = 65;

export type OptionType = { id: number; content: string };

type AnswersPropTypes = {
  options: OptionType[];
  isLoading: boolean;
  correctAnswerId?: number;
  selectedAnswerId?: number;
  onSelectAnswer: (option: OptionType) => void;
  disabled?: boolean;
};

export const Answers = ({
  options,
  isLoading,
  onSelectAnswer,
  disabled,
  correctAnswerId,
  selectedAnswerId,
}: AnswersPropTypes) => {
  const [delayedDisabled, setDelayedDisabled] = useState(disabled);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (!disabled) {
      timer = setTimeout(() => setDelayedDisabled(false), 1000);
    } else {
      setDelayedDisabled(disabled);
    }
    return () => clearTimeout(timer);
  }, [disabled]);

  const onSelect = useCallback(
    (option: OptionType) => () => !selectedAnswerId && !disabled && onSelectAnswer?.(option),
    [selectedAnswerId, onSelectAnswer],
  );

  const useSmallFontSize = options.some((o) => o.content.length > 100);

  return (
    <Container isLoading={isLoading} disabled={delayedDisabled}>
      {options.map((option, index) => (
        <Option
          key={option.id}
          onClick={onSelect(option)}
          alreadyAnswered={!!correctAnswerId}
          correct={correctAnswerId === option.id}
          withSmallSize={useSmallFontSize}
          selectedAnswer={selectedAnswerId === option.id}>
          {String.fromCharCode(UPPER_CASE_A_ASCII_CODE + index)}. {option.content}
        </Option>
      ))}
    </Container>
  );
};
