import styled, { css } from 'styled-components';
import { onVerticalScreen } from '../../utils/css';

export enum ButtonVariantEnum {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

const BUTTON_ANIMATION_DURATION = 0.5;

export const ButtonElem = styled.button<{ variant?: ButtonVariantEnum }>`
  font-family: ${({ theme }) => theme.fonts.citiSans};
  background-color: ${({ theme, variant = ButtonVariantEnum.PRIMARY }) =>
    variant === ButtonVariantEnum.PRIMARY ? theme.colors.darkBlue : 'transparent'};
  border: ${({ theme }) => `${theme.getValue(3)} solid ${theme.colors.darkBlue}`};
  outline: none;
  border-radius: ${({ theme }) => theme.getValue(10)};
  color: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => `${theme.getValue(16)} ${theme.getValue(32)}`};
  font-size: ${({ theme }) => theme.getFontSize(18)};
  font-weight: 400;
  cursor: pointer;
  margin: ${({ theme }) => `${theme.getValue(8)} ${theme.getValue(16)}`};
  &:disabled {
    opacity: 0.6;
  }

  &.interstate {
    font-family: ${({ theme }) => theme.fonts.interstate};
  }

  &.pressed {
    animation: pressed-in ${BUTTON_ANIMATION_DURATION}s forwards;
  }
  &.notpressed {
    animation: pressed-out ${BUTTON_ANIMATION_DURATION}s forwards;
  }

  ${({ theme }) =>
    onVerticalScreen(css`
      font-size: ${`${theme.getFontSize(32)}`};
    `)}
`;

export const ButtonContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;

    @keyframes pressed-out {
      0% {
        top: ${theme.getValue(2)};
      }
      100% {
        top: 0;
      }
    }

    @keyframes pressed-in {
      0% {
        top: 0;
      }
      100% {
        top: ${theme.getValue(2)};
      }
    }
  `}
`;
