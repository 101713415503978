import styled, { css } from 'styled-components';
import { onVerticalScreen } from '../../utils/css';

export const ScoreboardContainer = styled.div`
  background: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => `${theme.getValue(16)} ${theme.getValue(19)} ${theme.getValue(17)}`};
  border-radius: ${({ theme }) => `${theme.getValue(10)}`};
  display: flex;
  box-sizing: border-box;

  & ul {
    flex: 1;
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;

    & li {
      display: flex;
      align-items: center;

      & svg {
        width: ${({ theme }) => theme.getValue(25)};
        height: ${({ theme }) => theme.getValue(20)};
        margin-left: ${({ theme }) => theme.getValue(12)};
      }
    }

    & li + li {
      margin-top: ${({ theme }) => theme.getValue(12)};
    }

    & li > span {
      color: ${({ theme }) => theme.colors.blue};
      font-weight: 400;
      font-family: ${({ theme }) => theme.fonts.citiSans};
      font-size: ${({ theme }) => theme.getFontSize(20)};
      line-height: ${({ theme }) => theme.getFontSize(26)};
      width: ${({ theme }) => theme.getValue(120)};
    }

    & svg + span {
      color: ${({ theme }) => theme.colors.gray};
      font-weight: 400;
      font-family: ${({ theme }) => theme.fonts.citiSans};
      font-size: ${({ theme }) => theme.getFontSize(18)};
      width: fit-content;
      margin-left: auto;
    }
  }
  ${({ theme }) =>
    onVerticalScreen(css`
      padding: ${theme.getValue(24)} ${theme.getValue(24)} ${theme.getValue(25)};
      ul {
        li + li {
          margin-top: ${theme.getValue(40)};
        }
      }
    `)}
`;

export const Separator = styled.div`
  width: ${({ theme }) => theme.getValue(1)};
  background: ${({ theme }) => theme.colors.black};
  margin: 0 ${({ theme }) => theme.getValue(18)};
`;
