import { AvailableLanguages } from '../context/LanguageContext/types';

export const ordinalSuffixOf = (position: number, locale: AvailableLanguages = 'en'): string => {
  const decimal = position % 10;
  const hundred = position % 100;

  if (locale === 'es') {
    if (decimal == 1 || decimal == 3) {
      return position + 'ro';
    }
    if (decimal == 2) {
      return position + 'do';
    }
    if (decimal == 7) {
      return position + 'mo';
    }
    if (decimal == 8) {
      return position + 'vo';
    }
    if (decimal == 9) {
      return position + 'no';
    }

    return position + 'to';
  } else {
    if (decimal == 1 && hundred != 11) {
      return position + 'st';
    }
    if (decimal == 2 && hundred != 12) {
      return position + 'nd';
    }
    if (decimal == 3 && hundred != 13) {
      return position + 'rd';
    }

    return position + 'th';
  }
};
