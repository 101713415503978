import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg';
import { ReactComponent as XIcon } from '../../assets/icons/x.svg';
import { useLanguage } from '../../context/LanguageContext';
import { ScoreboardContainer, Separator } from './styles';

type Answer = {
  correct: boolean;
  points: number;
};

type ScoreboardPropTypes = {
  results: Answer[];
};

export function Scoreboard({ results }: ScoreboardPropTypes) {
  const { language } = useLanguage();

  const resultsWithNumber = results.map((answer, index) => {
    return {
      number: ++index,
      ...answer,
    };
  });

  return (
    <ScoreboardContainer>
      <ul>
        {resultsWithNumber.slice(0, 5).map((answer) => (
          <li key={answer.number}>
            <span>
              {language.question} {answer.number}
            </span>
            {answer.correct ? <CheckIcon /> : <XIcon />}
            {answer.correct && (
              <span>
                {answer.points} {language.pts}.
              </span>
            )}
          </li>
        ))}
      </ul>
      {resultsWithNumber.length > 5 && (
        <>
          <Separator />
          <ul>
            {resultsWithNumber.slice(5).map((answer) => (
              <li key={answer.number}>
                <span>
                  {language.question} {answer.number}
                </span>
                {answer.correct ? <CheckIcon /> : <XIcon />}
                {answer.correct && (
                  <span>
                    {answer.points} {language.pts}.
                  </span>
                )}
              </li>
            ))}
          </ul>
        </>
      )}
    </ScoreboardContainer>
  );
}
