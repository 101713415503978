import { AvailableLanguages } from '../context/LanguageContext/types';

const language = {
  en: 'en-US',
  es: 'es-MX',
} as const;

export function numberFormatter(locale: AvailableLanguages = 'en') {
  return new Intl.NumberFormat(language[locale]);
}
