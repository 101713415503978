/* eslint-disable  */
import axios from 'axios';

const baseURL = process.env.REACT_APP_API_URL;

const api = axios.create({
  baseURL,
});

/* eslint no-param-reassign: "off" */
const authenticationHeader = async (config: any): Promise<any> => {
  config.headers = {
    Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
  };

  return config;
};

api.interceptors.request.use(authenticationHeader);

export default api;
