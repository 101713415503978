/*  */
import React, { createContext, useContext, useState, FC } from 'react';
import playerService from '../../services/players';
import { useLanguage } from '../LanguageContext';
import { ContextProps, CreatePlayer, Player, ProviderProps } from './types';
export const PlayerContext = createContext<ContextProps>({
  player: undefined,
  isLoading: false,
  createPlayer: async () => {},
  setPlayer: () => {},
});

export const PlayerProvider: FC<ProviderProps> = ({ children }: ProviderProps) => {
  const [player, setPlayer] = useState<Player>();
  const [isLoading, setLoading] = useState(false);
  const { languageSelector } = useLanguage();

  const createPlayer = async (playerData: CreatePlayer) => {
    try {
      setLoading(true);
      const res = await playerService.create(playerData, languageSelector);
      setPlayer({ ...playerData, id: res.data.data.id });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <PlayerContext.Provider
      value={{
        player,
        setPlayer,
        createPlayer,
        isLoading,
      }}>
      {children}
    </PlayerContext.Provider>
  );
};

export default function usePlayerContext(): ContextProps {
  return useContext(PlayerContext);
}
