import { createContext, useContext, useState, FC } from 'react';
import gameService from '../../services/game';
import questionService from '../../services/questions';
import { useLanguage } from '../LanguageContext';
import usePlayerContext from '../PlayerContext';
import { ContextProps, GameSession, GameSessionAnswer, ProviderProps, Question } from './types';

export const GameContext = createContext<ContextProps>({
  gameSession: undefined,
  currentQuestion: undefined,
  termsModalOpen: false,
  sessionQuestions: [],
  sessionAnswers: [],
  currentQuestionIndex: 0,
  isLoading: false,
  createGameSession: async () => {},
  updateGameSessionAnswers: async () => {},
  setGameSession: () => {},
  setSessionAnswers: () => {},
  setCurrentQuestionIndex: () => {},
  setCurrentQuestion: () => {},
  setTermsModalOpen: () => {},
  setSessionQuestions: () => {},
});

export const GameProvider: FC<ProviderProps> = ({ children }) => {
  const [gameSession, setGameSession] = useState<GameSession>();
  const [sessionAnswers, setSessionAnswers] = useState<GameSessionAnswer[]>([]);
  const [sessionQuestions, setSessionQuestions] = useState<Question[]>([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState<Question>();
  const [isLoading, setLoading] = useState(false);
  const [termsModalOpen, setTermsModalOpen] = useState(false);
  const { player } = usePlayerContext();
  const { languageSelector } = useLanguage();

  const createGameSession = async () => {
    setLoading(true);
    try {
      const randomQuestionsResponse = await questionService.getRandomQuestions(languageSelector);
      if (player) {
        const res = await gameService.createGameSession(
          player.id,
          randomQuestionsResponse.data.map((v) => v.id),
          languageSelector,
        );
        setGameSession({ id: res.data.data.id });
        setSessionAnswers([]);
        setSessionQuestions(
          randomQuestionsResponse.data.map((question) => ({
            ...question,
            answers: question.answers.sort((a, b) => a.position - b.position),
          })),
        );
        setCurrentQuestion(randomQuestionsResponse.data[0]);
        setCurrentQuestionIndex(0);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const updateGameSessionAnswers = async (answerId: number, time: number) => {
    setLoading(true);
    try {
      if (gameSession) {
        await gameService.updateGameSessionAnswers(
          gameSession.id,
          [...sessionAnswers, { question: currentQuestion?.id || 0, answer: answerId, time }],
          languageSelector,
        );

        setSessionAnswers([
          ...sessionAnswers,
          { answer: answerId, question: currentQuestion?.id || 0, time },
        ]);

        const updatedGameSessionResponse = await gameService.getSessionInfo(
          gameSession.id,
          languageSelector,
        );

        setGameSession({ ...gameSession, ...updatedGameSessionResponse.data });

        await gameService.updateGameSessionPoints(
          gameSession.id,
          updatedGameSessionResponse.data.points,
          languageSelector,
        );
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <GameContext.Provider
      value={{
        gameSession,
        currentQuestion,
        termsModalOpen,
        sessionQuestions,
        sessionAnswers,
        currentQuestionIndex,
        isLoading,
        createGameSession,
        updateGameSessionAnswers,
        setGameSession,
        setSessionAnswers,
        setCurrentQuestionIndex,
        setCurrentQuestion,
        setTermsModalOpen,
        setSessionQuestions,
      }}>
      {children}
    </GameContext.Provider>
  );
};

export default function useGameContext(): ContextProps {
  return useContext(GameContext);
}
