import React, { useState, useEffect } from 'react';
import { CountdownWrapper, Circle1, Circle2, CountdownContainer, CountdownLabel } from './styles';

type CountdownPropTypes = {
  startValue?: number;
  countDownInterval?: number;
  isInverted?: boolean;
  size?: number;
  onFinish?: () => void;
};

export const Countdown: React.FC<CountdownPropTypes> = ({
  startValue = 3,
  countDownInterval = 1000,
  isInverted = false,
  size = 300,
  onFinish = () => {},
}) => {
  const [count, setCount] = useState(startValue);

  function tick() {
    setCount(count - 1);
  }

  useEffect(() => {
    if (count > 0) {
      if (count === 1) {
        const timerID = setTimeout(() => onFinish(), countDownInterval);
        return function cleanup() {
          clearTimeout(timerID);
        };
      }
      const timerID = setInterval(() => tick(), countDownInterval);
      return function cleanup() {
        clearInterval(timerID);
      };
    }
    return () => {};
  });

  return (
    <CountdownWrapper size={size} data-isinverted={isInverted ? 'isInverted' : null}>
      <Circle1 size={size} />
      <Circle2 size={size} />
      <CountdownContainer key={count} size={size}>
        <CountdownLabel size={size}>{count}</CountdownLabel>
      </CountdownContainer>
    </CountdownWrapper>
  );
};

export default Countdown;
