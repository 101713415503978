import { Outlet } from 'react-router-dom';
import { useCallback } from 'react';
import { useIdleTimer, workerTimers } from 'react-idle-timer';

import { Route } from '../../types/routes.d';
import { useSearchNavigate } from '../../hooks/useSearchNavigate';

export const IdleCountdown = ({
  route = Route.Home,
  msTimeout,
  disabled,
}: {
  route?: Route;
  msTimeout: number;
  disabled?: boolean;
}) => {
  const navigate = useSearchNavigate();

  const goToRoute = useCallback(() => navigate(route), [navigate]);

  useIdleTimer({
    timeout: msTimeout,
    onIdle: goToRoute,
    timers: workerTimers,
    startOnMount: true,
    startManually: process.env.REACT_APP_ENVIRONMENT === 'develop' || disabled,
  });

  return <Outlet />;
};
