import styled, { keyframes } from 'styled-components';

const animationTime = 1;
const animationScalingFactor = 1.25;
const animationScalingFactor2 = 1.5;

export const Animate = keyframes`
  100% {
    transform: scale(${animationScalingFactor});
  }
`;

export const heartBeat1 = keyframes`
    0% {transform: scale(${(1 + animationScalingFactor2) / 2}); opacity: 1;}
    100% {transform: scale(${animationScalingFactor2}); opacity: 0;}
`;

export const heartBeat2 = keyframes`
    0% {transform: scale(1); opacity: 0;}
    100% {transform: scale(${(1 + animationScalingFactor2) / 2}); opacity: 1;}
`;

export const CountdownContainer = styled.div<{ size: number }>`
  width: ${({ theme, size }) => theme.getValue(size / 1.503)};
  height: ${({ theme, size }) => theme.getValue(size / 1.503)};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: $white;
  position: relative;
  animation: ${Animate} ${animationTime}s linear;
  animation-fill-mode: both;
  box-shadow: 0 ${({ theme, size }) => theme.getValue(size / 83.6)}} ${({ theme, size }) =>
  theme.getValue(size / 41.8)} rgba(0, 0, 0, 0.15);
`;

export const CountdownLabel = styled.div<{ size: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.white};
  height: 100%;
  width: 100%;
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.darkBlue};
  font-family: ${({ theme }) => theme.fonts.citiSans};
  font-size: ${({ theme, size }) => theme.getFontSize(size / 2.787)};
  font-weight: bold;
  line-height: 4rem;
  line-height: ${({ theme, size }) => theme.getValue(size / 6.531)};
`;

export const CountdownWrapper = styled.div<{ size: number }>`
  width: ${({ theme, size }) => theme.getValue(size)};
  height: ${({ theme, size }) => theme.getValue(size * 1.01)};
  align-items: center;
  justify-content: center;
  background-size: contain;
  display: flex;
  &[data-isInverted] {
    ${CountdownContainer} {
      background-color: ${({ theme }) => theme.colors.white};
    }
    ${CountdownLabel} {
      color: ${({ theme }) => theme.colors.darkBlue};
    }
  }
`;

export const Center = styled.div`
  position: absolute;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Circle1 = styled.div<{ size: number }>`
  position: absolute;
  z-index: 0;
  width: ${({ theme, size }) => theme.getValue(size / 1.2)};
  height: ${({ theme, size }) => theme.getValue(size / 1.2)};
  border-radius: 50%;
  border: 2px solid white;
  opacity: 0;
  animation: ${heartBeat1} ${animationTime}s linear infinite;
`;

export const Circle2 = styled(Circle1)`
  animation: ${heartBeat2} ${animationTime}s linear infinite;
`;
