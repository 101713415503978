import { useLayoutEffect, useMemo, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import { PlayerProvider } from './context/PlayerContext';
import { GameProvider } from './context/GameContext';
import { LeaderboardProvider } from './context/LeaderboardContext';
import { Home } from './screens/Home';
import { Question } from './screens/Question';
import { Registration } from './screens/Registration';
import { Countdown } from './screens/Countdown';
import { Result } from './screens/Result';
import { Video } from './screens/Video';
import { IdleCountdown } from './components/IdleCountdown';
import { useDeviceDetector } from './hooks/useDeviceDetector';
import { Route as AppRoute } from './types/routes.d';
import { colors, ColorsType, fonts, FontsType } from './config/theme';
import { LanguageProvider } from './context/LanguageContext';
import { VerifyPlayer } from './components/VerifyPlayer';
import { TermsAndConditions } from './screens/TermsAndConditions';

// BG IMAGES
import HomeBg from './assets/backgrounds/home.webp';
import QuestionBg from './assets/backgrounds/question.webp';
import RegistrationBg from './assets/backgrounds/registration.webp';
import ResultsBg from './assets/backgrounds/results.webp';
import TermsBg from './assets/backgrounds/terms.png';
import { GlobalStyles } from './components/GlobalStyles';
import { getValue } from './utils/css';
import { useIsPortrait } from './hooks/useIsPortrait';

const Wrapper = styled.div`
  height: 100dvh;
`;
type Theme = {
  getValue: typeof getValue;
  getFontSize: typeof getValue;
  colors: ColorsType;
  fonts: FontsType;
  isMobile: boolean;
};

const useIsMounted = () => {
  const [isMounted, setIsMounted] = useState(false);

  useLayoutEffect(() => {
    setIsMounted(true);
  }, []);

  return isMounted;
};

function App() {
  const { isMobile } = useDeviceDetector();

  const isPortrait = useIsPortrait();

  const isMounted = useIsMounted();

  const theme = useMemo(
    (): Theme => ({
      getValue,
      colors,
      fonts,
      getFontSize: getValue,
      isMobile,
    }),
    [isMobile],
  );

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {isMounted && (
        <div style={{ opacity: 0, height: 0 }}>
          <img src={HomeBg} width="1" height="1" />
          <img src={QuestionBg} width="1" height="1" />
          <img src={RegistrationBg} width="1" height="1" />
          <img src={ResultsBg} width="1" height="1" />
          <img src={TermsBg} width="1" height="1" />
        </div>
      )}
      <Router>
        <LanguageProvider>
          <PlayerProvider>
            <GameProvider>
              <LeaderboardProvider>
                <Wrapper>
                  <Routes>
                    <Route path={AppRoute.Video} element={<Video />} />
                    <Route
                      element={
                        <IdleCountdown
                          disabled={!isPortrait}
                          route={AppRoute.Video}
                          msTimeout={1000 * 60 * 3}
                        />
                      }>
                      <Route
                        element={<IdleCountdown route={AppRoute.Home} msTimeout={1000 * 60} />}>
                        <Route element={<VerifyPlayer />}>
                          <Route path={AppRoute.Question} element={<Question />} />
                          <Route path={AppRoute.Countdown} element={<Countdown />} />
                          <Route path={AppRoute.Result} element={<Result />} />
                        </Route>
                        <Route path={AppRoute.Registration} element={<Registration />} />
                      </Route>
                      <Route path={AppRoute.Home} element={<Home />} />
                    </Route>
                    <Route path="*" element={<Navigate to={AppRoute.Home} />} />
                  </Routes>
                  <TermsAndConditions />
                </Wrapper>
              </LeaderboardProvider>
            </GameProvider>
          </PlayerProvider>
        </LanguageProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
