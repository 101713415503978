export type FontsType = {
  citiSans: string;
  citiSansText: string;
  interstate: string;
};

export const colors = {
  darkBlue: '#0E1531',
  scoreResult: '#002d70',
  blue: '#056dae',
  lightestBlue: '#00bdf0',
  titleBlue: '#255be3',
  gray: '#9d9d9d',
  placeholder: '#B6B6B6',
  answerGray: '#D8D8D8',
  white: '#ffffff',
  answerGraySelected: '#474747',
  black: '#000',
  red: '#ae0505',
  backgroundPurple: '#cb96fc',
};

export type ColorsType = typeof colors;

export const fonts: FontsType = {
  citiSans: 'Citi Sans, sans-serif',
  citiSansText: 'Citi Sans Text, sans-serif',
  interstate: 'interstate, sans-serif',
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  colors,
  fonts,
};
