import styled, { css } from 'styled-components';
import { onVerticalScreen } from '../../../../utils/css';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: ${({ theme }) => theme.getValue(27)};
  margin: ${({ theme }) => theme.getValue(15)} 0;

  & p {
    margin: 0;
    color: ${({ theme }) => theme.colors.white};
    font-size: ${({ theme }) => `${theme.getFontSize(24)}`};
    font-family: ${({ theme }) => theme.fonts.citiSans};
    text-align: center;
    max-width: ${({ theme }) => theme.getValue(550)};
  }

  & img {
    border-radius: ${({ theme }) => `${theme.getValue(8)}`};
  }

  .small {
    width: ${({ theme }) => theme.getValue(360)};
  }

  ${({ theme }) =>
    onVerticalScreen(css`
      margin: ${theme.getValue(24)} 0;
    `)}
`;
