import { Container } from './styles';
import { useSearchNavigate } from '../../hooks/useSearchNavigate';
import { Route } from '../../types/routes.d';

const VIDEO_URL = process.env.REACT_APP_IDLE_VIDEO_URL;

export const Video = () => {
  const navigate = useSearchNavigate();

  const onClick = () => {
    navigate(Route.Home);
  };

  return (
    <Container onClick={onClick}>
      <video autoPlay muted loop>
        <source src={VIDEO_URL} type="video/mp4" />
      </video>
    </Container>
  );
};
