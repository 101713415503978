import { useEffect, useState } from 'react';

export const useIsPortrait = () => {
  const [isPortraid, setIsPortrait] = useState(
    window.matchMedia('(orientation: portrait)').matches,
  );

  useEffect(() => {
    const handler = () => {
      console.log('calculando');
      setIsPortrait(window.matchMedia('(orientation: portrait)').matches);
    };
    window.addEventListener('orientationchange', handler);
    return () => window.removeEventListener('orientationchange', handler);
  }, []);

  return isPortraid;
};
