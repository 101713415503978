import React from 'react';
import { Footer } from '../components/Footer';
import { Container, Content } from './styles';

type LayoutPropTypes = {
  children: React.ReactNode;
  footerProps?: {
    onEndGame(): void;
  };
};

export function Layout({ footerProps, children }: LayoutPropTypes) {
  return (
    <Container>
      <Content>{children}</Content>

      <Footer onEndGame={footerProps?.onEndGame} />
    </Container>
  );
}
