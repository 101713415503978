import { createGlobalStyle, css } from 'styled-components';
import { onMobile, notOnMobile, minQuery } from '../../utils/css';
import { BREAKPOINTS, BREAKPOINTS_ARRAY } from '../../config/breakpoints';

const LIMIT_WIDTH = true;

const hide = css`
  visibility: hidden !important;
  display: none !important;
`;

const getMinPrecision = () => {
  if (
    typeof window !== 'undefined' &&
    (navigator.userAgent.indexOf('WOW64') != -1 ||
      navigator.userAgent.indexOf('Win64') != -1 ||
      navigator.userAgent.indexOf('x86_64') != -1)
  ) {
    return 4.9406564584124654e-324;
  } else {
    return 2.2250738585072014e-308;
  }
};

export const GlobalStyles = createGlobalStyle`
  *.hideIfMobile {
    ${onMobile(hide)}
  }

  *.hideIfNotMobile {
    ${notOnMobile(hide)}
  }

  *.sr-only {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important; 
    -webkit-clip-path: inset(50%) !important;
      clip-path: inset(50%) !important; 
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;            
  }

  *.sr-only-focusable:focus,
  *.sr-only-focusable:active {
    clip: auto !important;
    -webkit-clip-path: none !important;
      clip-path: none !important;
    height: auto !important;
    margin: auto !important;
    overflow: visible !important;
    width: auto !important;
    white-space: normal !important;
  }

  :root{
    ${BREAKPOINTS_ARRAY.map(({ conditionalVariable }) => {
      return `${conditionalVariable}: ${
        conditionalVariable === BREAKPOINTS.mobile.conditionalVariable ? 1 : 0
      };`;
    }).join('\n')}
    --integer-shf: ${getMinPrecision()};

    ${BREAKPOINTS_ARRAY.map(({ widthReference, proportionVariable }) => {
      if (LIMIT_WIDTH) {
        return `${proportionVariable}: calc(min(100vw, ${widthReference}px) / ${widthReference});`;
      }
      return `${proportionVariable}: calc(100vw / ${widthReference});`;
    }).join('\n')}

    ${BREAKPOINTS_ARRAY.map(({ conditionalVariable, proportionVariable, breakpointVariable }) => {
      const proportion = `var(${proportionVariable})`;
      const conditional = `var(${conditionalVariable})`;
      return `${breakpointVariable}: calc( ${proportion} * ${conditional} );`;
    }).join('\n')}

    ${BREAKPOINTS_ARRAY.map((breakpoint) => {
      return `
        @media only screen and (${minQuery(breakpoint)}) {
          ${BREAKPOINTS_ARRAY.map(({ conditionalVariable }) => {
            return `${conditionalVariable}: ${
              conditionalVariable === breakpoint.conditionalVariable ? 1 : 0
            };`;
          }).join('\n')}
        }
      `;
    })}
  }
`;
