import styled, { css } from 'styled-components';
import registrationBackground from '../../assets/backgrounds/registration.webp';
import registrationVerticalBackground from '../../assets/backgrounds/registration-vertical.webp';
import { onVerticalScreen } from '../../utils/css';

export const Container = styled.main`
  font-family: ${({ theme }) => theme.fonts.citiSans};
  background: url(${registrationBackground}) no-repeat right center,
    ${({ theme }) => theme.colors.backgroundPurple};
  background-size: cover;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.white};
  & > div {
    margin-bottom: ${({ theme }) => theme.getValue(35)};
  }
  & h2,
  & h1 {
    margin: 0;
    font-weight: 400;
  }
  & h2 {
    font-size: ${({ theme }) => theme.getFontSize(40)};
  }
  & h1 {
    font-size: ${({ theme }) => theme.getFontSize(80)};
  }
  ${({ theme }) =>
    onVerticalScreen(css`
      background: url(${registrationVerticalBackground}) no-repeat 50% 50%,
        ${theme.colors.backgroundPurple};
      background-size: auto 110%;
    `)}
`;
