import styled, { css } from 'styled-components';

export const Option = styled.div<{
  alreadyAnswered?: boolean;
  withSmallSize?: boolean;
  correct?: boolean;
  selectedAnswer?: boolean;
}>`
  flex: 1;
  font-family: ${({ theme }) => theme.fonts.citiSans};
  font-size: ${({ theme, withSmallSize }) => theme.getFontSize(withSmallSize ? 28 : 36)};
  padding-left: ${({ theme }) => theme.getValue(30)};
  border-color: transparent;
  border-style: solid;
  border-width: ${({ theme }) => theme.getValue(8)};
  display: flex;
  align-items: center;
  width: 100%;
  &:not(:first-child) {
    margin-top: 1px;
  }
  box-sizing: border-box;
  ${({ alreadyAnswered, correct, theme, selectedAnswer }) => {
    if (alreadyAnswered) {
      if (selectedAnswer)
        return css`
          background-color: ${theme.colors.titleBlue};
          color: ${theme.colors.white};
        `;
      if (correct)
        return css`
          border-color: ${theme.colors.red};
          color: ${theme.colors.answerGraySelected}80;
        `;
      return css`
        background-color: ${theme.colors.answererGray}26;
        color: ${theme.colors.answerGraySelected}80;
      `;
    }
    if (selectedAnswer) {
      return css`
        background-color: ${theme.colors.blue};
        color: ${theme.colors.white};
      `;
    }
    return css`
      background-color: ${theme.colors.titleBlue}35;
      cursor: pointer;
      color: ${theme.colors.black};
    `;
  }}
`;

export const Container = styled.div<{ isLoading: boolean; disabled?: boolean }>`
  width: 100%;
  height: 100%;
  max-height: ${({ theme }) => theme.getValue(1200)};
  display: flex;
  flex-direction: column;
  opacity: ${({ isLoading }) => (isLoading ? 0.7 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;
