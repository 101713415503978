import styled, { keyframes } from 'styled-components';
import termsBackground from '../../assets/backgrounds/terms.png';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const Container = styled.main`
  font-family: ${({ theme }) => theme.fonts.citiSans};
  background: url(${termsBackground}) no-repeat;
  z-index: 2;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  top: 0;
  opacity: 1;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  animation: ${fadeIn} 0.3s ease-in-out;
  transition: all 0.5s !important;

  &.closing {
    animation: ${fadeOut} 0.3s ease-in-out;
  }

  .card-wrapper {
    width: calc(100% - ${({ theme }) => theme.getFontSize(144)});
    height: calc(100% - ${({ theme }) => theme.getFontSize(144)});
    background-color: white;
    border-radius: 1rem;
    margin: ${({ theme }) => theme.getFontSize(36)};
    padding: ${({ theme }) => theme.getFontSize(36)};
    position: relative;
  }

  & h1 {
    font-size: ${({ theme }) => theme.getFontSize(36)};
    margin-bottom: ${({ theme }) => theme.getFontSize(42)};
  }

  .content {
    font-size: ${({ theme }) => theme.getFontSize(18)};
  }

  .close-btn {
    background: transparent;
    border: 0;
    position: absolute;
    text-transform: uppercase;
    top: ${({ theme }) => theme.getFontSize(48)};
    right: ${({ theme }) => theme.getFontSize(36)};
    font-size: ${({ theme }) => theme.getFontSize(18)};
  }
`;
