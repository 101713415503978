import { useCallback, useEffect, useState } from 'react';
import { ButtonElem, ButtonVariantEnum } from '../../components/Button';
import { Leaderboard } from '../../components/Leaderboard';
import { Scoreboard } from '../../components/Scoreboard';
import useGameContext from '../../context/GameContext';
import { useLanguage } from '../../context/LanguageContext';
import useLeaderboardContext from '../../context/LeaderboardContext';
import usePlayerContext from '../../context/PlayerContext';
import { useSearchNavigate } from '../../hooks/useSearchNavigate';
import { Layout } from '../../layouts/Layout';
import { Route } from '../../types/routes.d';
import { numberFormatter } from '../../utils/formatter';
import { ordinalSuffixOf } from '../../utils/stringHelper';
import { BottomMessage } from './components/BottomMessage';
import { Container } from './styles';

export function Result() {
  const { leaderboard, fetchCurrentSessionLeaderboard } = useLeaderboardContext();
  const { gameSession, createGameSession, sessionQuestions } = useGameContext();
  const { player } = usePlayerContext();
  const navigate = useSearchNavigate();
  const { language, languageSelector } = useLanguage();

  const [pressedHome, setPressedHome] = useState(false);
  const [pressedInHome, setPressedInHome] = useState(false);
  const [pressedPlayAgain, setPressedPlayAgain] = useState(false);
  const [pressedInPlayAgain, setPressedInPlayAgain] = useState(false);

  useEffect(() => {
    fetchCurrentSessionLeaderboard();
  }, []);

  const currentPosition = leaderboard.find((el) => el.id === gameSession?.id);

  const handleCreateGameSession = useCallback(async () => {
    await createGameSession();
    navigate(Route.Countdown);
  }, [createGameSession, navigate]);

  return (
    <Layout>
      <Container>
        <aside>
          <h2>{`${language.thanks_for_playing} ${player?.first_name} ${player?.last_name}!`}</h2>

          <h3>{language.check_your_leaderboard}</h3>

          <Leaderboard
            small
            highlightPosition={currentPosition ? currentPosition.position + 1 : undefined}
            results={leaderboard.map((session) => ({
              participantName: session.playerName,
              score: session.points,
              position: session.position + 1,
            }))}
          />
        </aside>

        <main>
          <h1>
            {`${language.you_were_ranked} ${ordinalSuffixOf(
              (currentPosition?.position || 0) + 1,
              languageSelector,
            )}!`}
            <br />
          </h1>
          <h2>{language.your_results}</h2>

          <Scoreboard
            results={(sessionQuestions || []).map((question) => ({
              points: gameSession?.answers?.find((a) => a.question.id === question.id)?.points || 0,
              correct:
                gameSession?.answers?.find((a) => a.question.id === question.id)?.isCorrect ||
                false,
            }))}
          />

          <p>
            {language.your_score}{' '}
            <span>
              {numberFormatter(languageSelector).format(gameSession?.points || 0)} {language.pts}.
            </span>
          </p>

          <BottomMessage position={(currentPosition?.position || 0) + 1} />

          <div>
            <ButtonElem
              className={pressedPlayAgain ? (pressedInPlayAgain ? 'pressed' : 'notpressed') : ''}
              onClick={() => {
                setPressedPlayAgain(true);
                setPressedInPlayAgain(true);
                setTimeout(() => {
                  setPressedInPlayAgain(false);
                }, 500);
                setTimeout(() => {
                  handleCreateGameSession();
                }, 1000);
              }}>
              {language.play_again}
            </ButtonElem>
            <ButtonElem
              className={pressedHome ? (pressedInHome ? 'pressed' : 'notpressed') : ''}
              onClick={() => {
                setPressedHome(true);
                setPressedInHome(true);
                setTimeout(() => {
                  setPressedInHome(false);
                }, 500);
                setTimeout(() => {
                  navigate(Route.Home);
                }, 1000);
              }}
              variant={ButtonVariantEnum.SECONDARY}>
              {language.home}
            </ButtonElem>
          </div>
        </main>
      </Container>
    </Layout>
  );
}
