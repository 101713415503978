import { css } from 'styled-components';
import { BREAKPOINTS, BREAKPOINTS_ARRAY, BreakpointType } from '../config/breakpoints';

export const minQuery = (breakpoint: BreakpointType) =>
  `min-width: ${breakpoint.widthReference + 1}px`;

export const maxQuery = (breakpoint: BreakpointType) => `max-width: ${breakpoint.widthReference}px`;

export const onMobile = (styles: any): any => css`
  @media only screen and (${maxQuery(BREAKPOINTS.mobile)}) {
    ${styles}
  }
`;

export const notOnMobile = (styles: any): any => css`
  @media only screen and (${minQuery(BREAKPOINTS.mobile)}) {
    ${styles}
  }
`;

export const onVerticalScreen = (styles: any): any => css`
  @media only screen and (min-width: 0px) and (orientation: portrait) {
    ${styles}
  }
`;

const getLastValue = <T>(values: [T, ...T[]], index: number) => {
  const safeIndex = Math.min(index, values.length - 1);
  return values[safeIndex];
};

export const selectBreakpointValue = <T>(...values: [T, ...T[]]) => {
  const breakpointValues = BREAKPOINTS_ARRAY.map(({ conditionalVariable }, breakpointIndex) => {
    const value = getLastValue(values, breakpointIndex);
    const conditional = `var(${conditionalVariable})`;
    return `(${value}) * ${conditional}`;
  });
  return `calc(${breakpointValues.join(' + ')})`;
};

const getExpression = (values: [number, ...number[]]) =>
  BREAKPOINTS_ARRAY.map(({ breakpointVariable }, breakpointIndex) => {
    const value = getLastValue(values, breakpointIndex);
    return `(${value}) * var(${breakpointVariable})`;
  }).join(' + ');

export const getValue = (...values: [number, ...number[]]) => `calc(${getExpression(values)} )`;

export const getRoundValue = getValue;
