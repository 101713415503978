/*  */
import { createContext, useContext, FC, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { languages } from '../../languages';
import { AvailableLanguages, ContextProps, ProviderProps } from './types';

const DEFAULT_LANGUAGE_SELECTOR: AvailableLanguages = 'en';
const DEFAULT_LANGUAGE = languages[DEFAULT_LANGUAGE_SELECTOR];

export const LanguageContext = createContext<ContextProps>({
  language: DEFAULT_LANGUAGE,
  languageSelector: DEFAULT_LANGUAGE_SELECTOR,
});

export const LanguageProvider: FC<ProviderProps> = ({ children }: ProviderProps) => {
  const [query] = useSearchParams();

  const value = useMemo<ContextProps>(() => {
    const langSearchQuery = (query.get('lang') || DEFAULT_LANGUAGE_SELECTOR) as AvailableLanguages;

    const languageSelector = languages[langSearchQuery]
      ? langSearchQuery
      : DEFAULT_LANGUAGE_SELECTOR;

    return {
      language: languages[languageSelector],
      languageSelector,
    };
  }, [query]);

  return <LanguageContext.Provider value={value}>{children}</LanguageContext.Provider>;
};

export const useLanguage = (): ContextProps => useContext(LanguageContext);
