import styled, { css } from 'styled-components';
import resultsBackground from '../../assets/backgrounds/results.webp';
import resultsVerticalBackground from '../../assets/backgrounds/results-vertical.webp';
import { fadeIn } from '../../utils/Animations';
import { onVerticalScreen } from '../../utils/css';

export const Container = styled.div`
  display: flex;
  height: 100%;
  animation: ${fadeIn} 1s;
  animation-fill-mode: forwards;

  & aside {
    display: flex;
    flex-direction: column;
    row-gap: ${({ theme }) => `${theme.getValue(13)}`};
    padding: ${({ theme }) => `${theme.getValue(33)}`};
    max-width: ${({ theme }) => `${theme.getValue(383)}`};

    & > h2,
    & > h3 {
      margin: 0;
      font-family: ${({ theme }) => theme.fonts.citiSans};
      font-weight: 400;
    }

    & h2 {
      color: ${({ theme }) => theme.colors.titleBlue};
      font-size: ${({ theme }) => `${theme.getFontSize(25)}`};
      line-height: ${({ theme }) => `${theme.getFontSize(35)}`};
      max-width: ${({ theme }) => `${theme.getValue(288)}`};
    }

    & h3 {
      font-size: ${({ theme }) => `${theme.getFontSize(20)}`};
      line-height: ${({ theme }) => `${theme.getFontSize(30)}`};
      max-width: ${({ theme }) => `${theme.getValue(288)}`};
    }
  }

  & main {
    width: 100%;
    padding: ${({ theme }) => `${theme.getValue(33)} ${theme.getValue(47)}`};
    background: url(${resultsBackground}) 40% center, #fa82cb;
    background-size: auto 120%;

    & > h1,
    & > h2,
    & > p {
      margin: 0;
      color: ${({ theme }) => theme.colors.white};
      font-family: ${({ theme }) => theme.fonts.citiSans};
    }

    h1,
    h2,
    p {
      text-align: center;
      font-weight: 400;
    }

    & h1 {
      font-size: ${({ theme }) => `${theme.getFontSize(35)}`};
    }

    & h2 {
      font-size: ${({ theme }) => `${theme.getFontSize(30)}`};
      margin-bottom: ${({ theme }) => `${theme.getValue(27)}`};
    }

    & > p:first-of-type {
      margin-top: ${({ theme }) => `${theme.getValue(25)}`};
      font-size: ${({ theme }) => `${theme.getFontSize(25)}`};
      text-align: center;

      & span {
        color: ${({ theme }) => theme.colors.scoreResult};
      }
    }

    & div:last-child {
      display: flex;
      justify-content: center;
      gap: ${({ theme }) => `${theme.getValue(20)}`};

      & button {
        width: ${({ theme }) => `${theme.getValue(220)}`};
        text-transform: uppercase;
      }
    }
  }
  ${({ theme }) =>
    onVerticalScreen(css`
      & aside {
        padding: ${theme.getValue(100)} ${theme.getValue(33)};
        row-gap: ${theme.getValue(24)};
      }
      & main {
        padding: ${theme.getValue(100)} ${theme.getValue(47)};
        background: url(${resultsVerticalBackground}) 75% 50%, #fa82cb;
        background-size: auto 110%;
        & div:last-child {
          & button {
            margin: 0;
            width: ${theme.getValue(240)};
            padding: ${theme.getValue(8)} ${theme.getValue(8)};
          }
        }
        & > p:first-of-type {
          margin-top: ${theme.getValue(100)};
        }
      }
    `)}
`;
