import { useEffect, useState } from 'react';
import useGameContext from '../../context/GameContext';
import { Container } from './styles';

export const TermsAndConditions = () => {
  const { termsModalOpen, setTermsModalOpen } = useGameContext();
  const [closing, setClosing] = useState(false);

  useEffect(() => {
    if (!termsModalOpen) {
      setClosing(false);
    }
  }, [termsModalOpen]);

  return (
    <Container
      style={{ display: !termsModalOpen ? 'none' : 'block' }}
      className={closing ? 'closing' : ''}>
      <div className="card-wrapper">
        <h1>Terms & Conditions</h1>
        <button
          onClick={() => {
            setClosing(true);
            setTimeout(() => {
              setTermsModalOpen(false);
            }, 300);
          }}
          className={closing ? 'close-btn closing' : 'close-btn'}>
          Close
        </button>
        <div className="content">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et tristique quam. Ut nec
            nisl eget augue efficitur ultrices. Nullam dapibus orci at lectus tristique
            sollicitudin. Duis tincidunt purus vitae urna dictum interdum.
          </p>
          <p>
            Sed non fermentum elit. Nulla facilisi. Nulla id fringilla ipsum, ac fringilla lectus.
            Sed facilisis mauris ut lacus commodo, ut malesuada elit tincidunt. Sed laoreet arcu et
            ligula faucibus, ac faucibus erat aliquet.
          </p>{' '}
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et tristique quam. Ut nec
            nisl eget augue efficitur ultrices. Nullam dapibus orci at lectus tristique
            sollicitudin. Duis tincidunt purus vitae urna dictum interdum.
          </p>
          <p>
            Sed non fermentum elit. Nulla facilisi. Nulla id fringilla ipsum, ac fringilla lectus.
            Sed facilisis mauris ut lacus commodo, ut malesuada elit tincidunt. Sed laoreet arcu et
            ligula faucibus, ac faucibus erat aliquet.
          </p>
        </div>
      </div>
    </Container>
  );
};
