import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { Route } from '../../types/routes.d';
import usePlayerContext from '../../context/PlayerContext';
import { useSearchNavigate } from '../../hooks/useSearchNavigate';

export const VerifyPlayer = () => {
  const navigate = useSearchNavigate();
  const player = usePlayerContext();
  useEffect(() => {
    if (!player.player) navigate(Route.Home);
  }, [player, navigate]);

  return <Outlet />;
};
