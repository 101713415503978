export type BreakpointType = {
  maxScreenWidth: number;
  widthReference: number;
  heightReference: number;
  conditionalVariable: string;
  proportionVariable: string;
  breakpointVariable: string;
};

export const BREAKPOINTS: {
  [key: string]: BreakpointType;
} = {
  mobile: {
    maxScreenWidth: 767,
    widthReference: 375,
    heightReference: 735,
    conditionalVariable: '--is-mobile',
    proportionVariable: '--mobile-proportion',
    breakpointVariable: '--mobile-constant',
  },
  desktop: {
    maxScreenWidth: 1366,
    widthReference: 1000,
    heightReference: 768,
    conditionalVariable: '--is-desktop',
    proportionVariable: '--desktop-proportion',
    breakpointVariable: '--desktop-constant',
  },
};

export const BREAKPOINTS_ARRAY = [BREAKPOINTS.mobile, BREAKPOINTS.desktop];
