import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Answers, OptionType } from '../../components/Answers';
import { Timer } from '../../components/Timer';
import useGameContext from '../../context/GameContext';
import { Countdown as CountdownBase } from '../../components/Countdown';
import usePlayerContext from '../../context/PlayerContext';
import { Layout } from '../../layouts/Layout';
import { Route } from '../../types/routes.d';
import { Container, Header, LeftColumn, RightColumn, CountdownWrapper } from './styles';
import { useSearchNavigate } from '../../hooks/useSearchNavigate';
import { useLanguage } from '../../context/LanguageContext';
import { useDebounceFunction } from '../../hooks/useDebounceFunction';

export const Question = () => {
  const {
    currentQuestion,
    sessionQuestions,
    setCurrentQuestionIndex,
    setCurrentQuestion,
    currentQuestionIndex,
    gameSession,
    updateGameSessionAnswers,
  } = useGameContext();

  const currentQuestionIndexRef = useRef(currentQuestionIndex);

  useEffect(() => {
    currentQuestionIndexRef.current = currentQuestionIndex;
  }, [currentQuestionIndex]);

  const navigate = useSearchNavigate();
  const { player } = usePlayerContext();
  const [loadingNewQuestion, setLoadingNewQuestion] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [correctAnswersVisible, setCorrectAnswersVisible] = useState(false);
  const [answerIsSelected, setAnswerIsSelected] = useState(false);
  const { language } = useLanguage();

  const question = useMemo(() => currentQuestion?.content, [currentQuestion]);
  const playerName = `${player?.first_name} ${player?.last_name}`;
  const totalNumberOfQuestions = 10;
  const points = gameSession?.points || 0;

  const currentAnswer = gameSession?.answers?.find(
    (ans) => ans.question.id === currentQuestion?.id,
  );

  const nextQuestion = useCallback(() => {
    const currentQuestionNumber = currentQuestionIndexRef.current + 1;
    const isLastQuestion = currentQuestionNumber >= sessionQuestions.length;

    setLoadingNewQuestion(false);
    setCorrectAnswersVisible(false);
    setAnswerIsSelected(false);
    if (isLastQuestion) {
      navigate(Route.Result);
    } else {
      setCurrentQuestionIndex((prev) => prev + 1);
      setCurrentQuestion(sessionQuestions[currentQuestionNumber]);
    }
  }, [
    currentQuestionIndex,
    sessionQuestions,
    setCurrentQuestionIndex,
    setLoadingNewQuestion,
    setCorrectAnswersVisible,
    setAnswerIsSelected,
    setCurrentQuestion,
    navigate,
  ]);

  const showCorrectAnswers = () => {
    setCorrectAnswersVisible(true);
  };

  const checkEnd = () => {
    const currentQuestionNumber = currentQuestionIndexRef.current + 1;
    if (currentQuestionNumber >= totalNumberOfQuestions)
      setTimeout(() => {
        nextQuestion();
      }, 3000);
  };

  const debouncedOnSelectedAnswer = useDebounceFunction((option: OptionType) => {
    if (!answerIsSelected && !loadingNewQuestion) {
      updateGameSessionAnswers(option?.id, currentTime * 1000);
      setAnswerIsSelected(true);
      setLoadingNewQuestion(true);
      showCorrectAnswers();
      checkEnd();
    }
  }, 100);

  const onTimeFinish = () => {
    showCorrectAnswers();
    setLoadingNewQuestion(true);
    setCorrectAnswersVisible(true);
    checkEnd();
  };

  const leftColumnRender = useMemo(() => {
    const haveNextQuestion = currentQuestionIndex + 1 < sessionQuestions.length;
    if (loadingNewQuestion && correctAnswersVisible) {
      return (
        <>
          {haveNextQuestion ? (
            <>
              <h1 className="fade-in">
                {language.next_question}
                <br />
                {language['ready?']}
              </h1>
              <CountdownWrapper className="fade-in">
                <CountdownBase size={200} onFinish={nextQuestion} />
              </CountdownWrapper>
            </>
          ) : (
            <>
              <h1 className="fade-in">{language.questions_completed}</h1>
              <h3 className="fade-in">{language.ready_to_see_your_results}</h3>
            </>
          )}
        </>
      );
    }

    return (
      <h1 className={(question?.length || 0) >= 75 ? 'fade-in small-text' : 'fade-in'}>
        {question}
      </h1>
    );
  }, [
    currentQuestionIndex,
    correctAnswersVisible,
    sessionQuestions,
    nextQuestion,
    language,
    loadingNewQuestion,
  ]);

  return (
    <Layout
      footerProps={{
        onEndGame: () => navigate(Route.Result),
      }}>
      <Container>
        <LeftColumn>
          <Timer
            setCurrentTime={setCurrentTime}
            loading={loadingNewQuestion}
            seconds={25}
            onFinish={onTimeFinish}
          />
          {leftColumnRender}
        </LeftColumn>

        <RightColumn
          className={loadingNewQuestion && correctAnswersVisible ? 'fade-out' : 'fade-in'}>
          <Header>
            <div>
              <h2>{playerName}</h2>
              <p>
                {currentQuestionIndex + 1}/{totalNumberOfQuestions} {language.questions}
              </p>
            </div>
            <div>
              <p>
                {points} {language.pts}.
              </p>
            </div>
          </Header>
          <Answers
            disabled={(loadingNewQuestion && correctAnswersVisible) || answerIsSelected}
            isLoading={loadingNewQuestion && !currentAnswer?.answer?.id}
            options={currentQuestion?.answers || []}
            selectedAnswerId={currentAnswer?.answer?.id}
            correctAnswerId={currentAnswer?.correctAnswer}
            onSelectAnswer={debouncedOnSelectedAnswer}
          />
        </RightColumn>
      </Container>
    </Layout>
  );
};
