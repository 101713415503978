/*  */
import { AxiosResponse } from 'axios';
import { AvailableLanguages } from '../context/LanguageContext/types';
import api from './api';

interface CreateCandidate {
  first_name: string;
  last_name: string;
  soeid: string;
}

interface CreateCandidateResponse {
  data: {
    id: number;
  };
}

const playerService = {
  create: (
    data: CreateCandidate,
    locale: AvailableLanguages = 'en',
  ): Promise<AxiosResponse<CreateCandidateResponse>> =>
    api.post<any>(`/api/players`, {
      data: {
        ...data,
        locale,
      },
    }),
};

export default playerService;
