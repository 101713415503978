import styled, { css } from 'styled-components';
import registrationBackground from '../../assets/backgrounds/registration.webp';
import registrationVerticalBackground from '../../assets/backgrounds/registration-vertical.webp';
import { onVerticalScreen } from '../../utils/css';

export const Container = styled.main`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: ${({ theme }) => `${theme.getValue(100)} ${theme.getValue(77)}`};
  background: url(${registrationBackground}) no-repeat 75% 50%,
    ${({ theme }) => theme.colors.backgroundPurple};
  background-size: auto 120%;
  column-gap: ${({ theme }) => `${theme.getValue(132)}`};
  box-sizing: border-box;
  font-family: ${({ theme }) => theme.fonts.citiSans};

  h1,
  h2 {
    margin: 0;
    color: ${({ theme }) => theme.colors.white};
    font-size: ${({ theme }) => `${theme.getFontSize(36)}`};
    font-weight: 400;
  }

  h1 {
    margin-top: ${({ theme }) => `${theme.getValue(49)}`};
    font-size: ${({ theme }) => `${theme.getFontSize(36)}`};
    line-height: ${({ theme }) => `${theme.getFontSize(40)}p`};
    max-width: ${({ theme }) => `${theme.getValue(293)}`};
  }

  h2 {
    margin-bottom: ${({ theme }) => `${theme.getValue(18)}`};
  }

  & > div {
    width: 100%;
    max-width: ${({ theme }) => `${theme.getValue(425)}`};
    &:first-child {
      max-width: ${({ theme }) => `${theme.getValue(313)}`};
    }
  }

  form {
    row-gap: ${({ theme }) => theme.getValue(23)};
    display: flex;
    flex-direction: column;
    width: 100%;

    div {
      width: 100%;
    }

    input {
      font-weight: 400;
      letter-spacing: ${({ theme }) => `${theme.getFontSize(1)}`};
      width: 100%;
    }

    button {
      text-transform: uppercase;
      letter-spacing: ${({ theme }) => `${theme.getFontSize(1)}`};
      width: 100%;
      margin: 0;
    }
  }

  ${({ theme }) =>
    onVerticalScreen(css`
      background: url(${registrationVerticalBackground}) no-repeat 50% 50%,
        ${theme.colors.backgroundPurple};
      background-size: auto 110%;
      align-items: flex-start;
      h1 {
        max-width: ${theme.getValue(260)};
      }
      div:nth-child(2) {
        margin-top: ${theme.getValue(180)};
      }
      form button {
        padding: ${`${theme.getValue(10)} ${theme.getValue(32)}`};
      }
    `)}

  .checkbox-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    input {
      width: ${({ theme }) => `${theme.getFontSize(27)}`};
      height: ${({ theme }) => `${theme.getFontSize(27)}`};
    }

    .label {
      width: 100%;
      color: white;
      font-size: ${({ theme }) => `${theme.getFontSize(18)}`};
      line-height: ${({ theme }) => `${theme.getFontSize(24)}`};
      letter-spacing: ${({ theme }) => `${theme.getFontSize(-0.128)}`};
    }

    .terms-link {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

export const Description = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.colors.white};
  margin-top: ${({ theme }) => theme.getValue(32)};
  font-size: ${({ theme }) => theme.getFontSize(20)};
  font-weight: 400;
  line-height: ${({ theme }) => theme.getFontSize(35)};
`;

export const ErrorMessage = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.getFontSize(18)};
    color: ${theme.colors.red};
    font-family: ${theme.fonts.citiSans};
    margin-top: ${theme.getValue(9)};
  `}
  font-weight: 400;
  margin-bottom: 0;
`;
