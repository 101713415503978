import { useCallback, useMemo, Fragment } from 'react';
import { useLanguage } from '../../context/LanguageContext';
import { numberFormatter } from '../../utils/formatter';

import { ColorsEnum, Text, Container, Title, Scores, Position } from './styles';

type ResultType = {
  position: number;
  participantName: string;
  score: number;
};

type LeaderboardPropTypes = {
  borderless?: boolean;
  highlightPosition?: number;
  results: ResultType[];
  small?: boolean;
};

export const Leaderboard = ({
  borderless,
  highlightPosition,
  results,
  small,
}: LeaderboardPropTypes) => {
  const { language, languageSelector } = useLanguage();

  const maxLengthResults = useMemo<ResultType[]>(() => {
    const getIndexes = () => {
      return [0, 9];
    };
    const indexes = getIndexes();

    return results.slice(...indexes);
  }, [results, highlightPosition]);

  const getColors = useCallback(
    (position: number) => {
      if (highlightPosition) {
        return {
          positionColor: position !== highlightPosition ? ColorsEnum.GRAY : ColorsEnum.BLACK,
          scoreColor: position !== highlightPosition ? ColorsEnum.GRAY : ColorsEnum.DARK_BLUE,
        };
      }

      return {
        positionColor: ColorsEnum.BLACK,
        scoreColor: ColorsEnum.DARK_BLUE,
      };
    },
    [highlightPosition],
  );

  return (
    <Container borderless={borderless} small={small}>
      <Title>{language.leaderboard}</Title>
      <Scores>
        {maxLengthResults.map(({ position, participantName, score }) => (
          <Fragment key={position}>
            <Position color={getColors(position).positionColor}>{position}.</Position>
            <Text
              color={
                highlightPosition && position !== highlightPosition ? ColorsEnum.GRAY : undefined
              }>
              {participantName}
            </Text>
            <Text color={getColors(position).scoreColor}>
              {numberFormatter(languageSelector).format(score)}
            </Text>
          </Fragment>
        ))}
      </Scores>
    </Container>
  );
};
