import styled, { css } from 'styled-components';
import { onVerticalScreen } from '../../utils/css';

export enum ColorsEnum {
  GRAY = 'gray',
  DARK_BLUE = 'darkBlue',
  BLACK = 'black',
}

export const Text = styled.p<{ color?: ColorsEnum }>`
  font-weight: 400;
  font-family: ${({ theme }) => theme.fonts.citiSans};
  color: ${({ color, theme }) => color && theme.colors[color]};
  font-size: ${({ theme }) => theme.getFontSize(18)};
  margin-top: 0;
  margin-bottom: 0;
  text-overflow: ellipsis;
`;

export const Position = styled(Text)`
  text-align: end;
  margin-right: ${({ theme }) => `${theme.getValue(10)}`};
`;

export const Scores = styled.div`
  display: grid;
  grid-template-columns: min-content auto min-content;
  max-height: 100%;
`;

export const Title = styled(Text).attrs({
  color: ColorsEnum.DARK_BLUE,
})`
  color: ${({ theme }) => theme.colors.titleBlue};
  font-size: ${({ theme }) => theme.getFontSize(20)};
  margin-top: ${({ theme }) => theme.getValue(2)};
  margin-bottom: ${({ theme }) => theme.getValue(25)};
  ${({ theme }) =>
    onVerticalScreen(css`
      font-size: ${theme.getFontSize(30)};
    `)}
`;

export const Container = styled.div<{ borderless?: boolean; small?: boolean }>`
  background: white;
  border: ${({ borderless }) => !borderless && '1px solid black'};
  width: ${({ theme, small }) => theme.getValue(small ? 240 : 288)};
  height: ${({ theme, small }) => theme.getValue(small ? 350 : 437)};
  border-radius: ${({ theme }) => theme.getValue(10)};
  padding: ${({ theme }) => theme.getValue(24)};
  display: flex;
  flex-direction: column;
  ${Scores} {
    row-gap: ${({ theme, small }) => theme.getValue(small ? 13 : 22)};
  }
  ${({ theme, small }) =>
    onVerticalScreen(css`
      width: ${theme.getFontSize(small ? 300 : 800)};
      height: ${theme.getValue(small ? 800 : 437)};
      ${Scores} {
        row-gap: ${theme.getValue(22)};
      }
      ${Title} {
        margin-top: ${theme.getValue(small ? 20 : 6)};
        margin-bottom: ${theme.getValue(small ? 60 : 20)};
      }
    `)}
`;
