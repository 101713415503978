import { Container } from './styles';
import qrCode from '../../../../assets/qr-code.png';
import { useLanguage } from '../../../../context/LanguageContext';

type BottomMessagePropTypes = {
  position: number;
};

export function BottomMessage({ position }: BottomMessagePropTypes) {
  const { language } = useLanguage();

  return (
    <Container>
      {position < 10 ? (
        <p>{language.amazing_job}</p>
      ) : (
        <>
          <p className="small">{language.no_worries}</p>

          <img height={132} width={131} src={qrCode} alt={language.citis_trivia_qr_code} />
        </>
      )}
    </Container>
  );
}
