import styled from 'styled-components';
import { ButtonElem as BaseButton } from '../Button';

export const FooterContainer = styled.footer`
  box-sizing: border-box;
  width: 100%;
  padding: ${({ theme }) => `${theme.getValue(25)} ${theme.getValue(46)}`};
  background: ${({ theme }) => theme.colors.darkBlue};
  display: flex;
  align-items: center;
  justify-content: space-between;

  .buttons-wrapper {
    width: ${({ theme }) => `${theme.getValue(360)}`};
    justify-content: flex-end;
    display: flex;
    flex-direction: row;
  }

  .buttons-wrapper-single {
    width: ${({ theme }) => `${theme.getValue(350)}`};
    justify-content: flex-end;
    display: flex;
    flex-direction: row;
  }
`;

export const Button = styled(BaseButton)`
  padding: 0;
  margin: 0;
  position: relative;
  text-transform: uppercase;
  background: transparent;
  border: none;
  font-weight: 400;
  font-size: ${({ theme }) => `${theme.getFontSize(15)}`};
`;

export const LeftContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: ${({ theme }) => `${theme.getValue(250)}`};
`;
