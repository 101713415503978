/*  */
import { AxiosResponse } from 'axios';
import { AvailableLanguages } from '../context/LanguageContext/types';
import api from './api';

interface RandomQuestions {
  id: number;
  content: string;
  answers: {
    id: number;
    position: number;
    content: string;
  }[];
}

const questionService = {
  getRandomQuestions: (
    locale: AvailableLanguages = 'en',
  ): Promise<AxiosResponse<RandomQuestions[]>> =>
    api.get<any>(`/api/random-questions?locale=${locale}`),
};

export default questionService;
