import styled from 'styled-components';

export const Input = styled.input<{ error?: boolean }>`
  box-sizing: border-box;
  font-weight: bold;
  border: none;
  outline: none;
  ${({ theme, error }) => `
    height: ${theme.getValue(58)};
    padding: 0 ${theme.getValue(20)};
    background-color: ${theme.colors.lightGray};
    font-family: ${theme.fonts.citiSans};
    font-size: ${theme.getFontSize(18)};
    color: ${error ? theme.colors.red : theme.colors.black};

    --placeholder-color: ${error ? theme.colors.red : theme.colors.placeholder};

    &::placeholder {
      color: var(--placeholder-color);
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: var(--placeholder-color);
    }

    &::-ms-input-placeholder {
      color: var(--placeholder-color);
    }
  `}
`;
