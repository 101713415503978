/*  */
import { AxiosResponse } from 'axios';
import { AvailableLanguages } from '../context/LanguageContext/types';
import api from './api';

interface Leaderboard {
  id: number;
  playerName: string;
  points: number;
  position: number;
  answers: {
    id: number;
    time: number;
    question: {
      id: number;
    };
    points: number;
    answer: {
      id: number;
    };
    isCorrect: boolean;
    correctAnswer: number;
  }[];
}

interface GameSessionAnswer {
  question: number;
  answer: number;
  time: number;
}

const gameService = {
  getTop9Leaderboard: (locale: AvailableLanguages = 'en'): Promise<AxiosResponse<Leaderboard[]>> =>
    api.get<any>(`/api/leaderboard/top10?locale=${locale}`),
  getSessionLeaderboard: (
    id: number,
    locale: AvailableLanguages = 'en',
  ): Promise<AxiosResponse<Leaderboard[]>> =>
    api.get<any>(`/api/leaderboard/${id}?locale=${locale}`),
  getSessionInfo: (
    id: number,
    locale: AvailableLanguages = 'en',
  ): Promise<AxiosResponse<Leaderboard>> =>
    api.get<any>(`/api/leaderboard/me/${id}?locale=${locale}`),
  createGameSession: (
    player: number,
    questions: number[],
    locale: AvailableLanguages = 'en',
  ): Promise<AxiosResponse<{ data: { id: number } }>> =>
    api.post<any>(`/api/game-sessions`, {
      data: {
        questions,
        player,
        locale,
      },
    }),

  updateGameSessionAnswers: (
    gameSessionId: number,
    answers: GameSessionAnswer[],
    locale: AvailableLanguages = 'en',
  ): Promise<AxiosResponse<{ data: { id: number } }>> =>
    api.put<any>(`/api/game-sessions/${gameSessionId}`, {
      data: {
        answers,
        locale,
      },
    }),
  updateGameSessionPoints: (
    gameSessionId: number,
    points: number,
    locale: AvailableLanguages = 'en',
  ): Promise<AxiosResponse<{ data: { id: number } }>> =>
    api.put<any>(`/api/game-sessions/${gameSessionId}`, {
      data: {
        points,
        locale,
      },
    }),
};

export default gameService;
