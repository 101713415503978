import { useLanguage } from '../../context/LanguageContext';
import { Logo as CitiLogo } from './styles';
import LogoSrc from '../../assets/logo.webp';

type LogoPropTypes = {
  width?: number;
  height?: number;
  className?: string;
};

export const Logo = ({ height = 104, width = 161, className }: LogoPropTypes) => {
  const { language } = useLanguage();
  return (
    <CitiLogo
      src={LogoSrc}
      className={className}
      height={height}
      width={width}
      alt={language.citis_logo}
    />
  );
};
