import styled, { css } from 'styled-components';
import questionBackground from '../../assets/backgrounds/question.webp';
import questionVerticalBackground from '../../assets/backgrounds/question-vertical.webp';
import { Container as Timer } from '../../components/Timer/styles';
import { fadeIn, fadeOut } from '../../utils/Animations';
import { onVerticalScreen } from '../../utils/css';

export const Container = styled.div`
  display: flex;
  height: 100%;
  font-family: ${({ theme }) => theme.fonts.citiSans};

  .fade-in {
    animation: ${fadeIn} 0.5s;
    animation-fill-mode: forwards;
  }

  .fade-out {
    animation: ${fadeOut} 0.5s;
    animation-delay: 2.5s;
    animation-fill-mode: forwards;
  }
`;

export const CountdownWrapper = styled.div`
  margin-top: ${({ theme }) => theme.getValue(32)};
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  ${({ theme }) =>
    onVerticalScreen(css`
      margin-top: ${theme.getValue(64)};
    `)}
`;

export const LeftColumn = styled.div`
  background: url(${questionBackground}) no-repeat, #f96a6c;
  background-position: 22% top;
  background-size: auto 120%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: ${({ theme }) => theme.getValue(348)};
  padding: ${({ theme }) => theme.getValue(26)};
  & ${Timer} {
    margin-bottom: ${({ theme }) => theme.getValue(80)};
  }
  & h1 {
    font-weight: 400;
    color: ${({ theme }) => theme.colors.white};
    font-size: ${({ theme }) => `${theme.getFontSize(35)}`};

    &.small-text {
      font-size: ${({ theme }) => `${theme.getFontSize(28)}`};
    }
  }

  & h3 {
    font-weight: 400;
    color: ${({ theme }) => theme.colors.white};
    font-size: ${({ theme }) => `${theme.getFontSize(28)}`};
  }
  ${({ theme }) =>
    onVerticalScreen(css`
      background: url(${questionVerticalBackground}) no-repeat, #f96a6c;
      background-position: 22% 20%;
      background-size: auto 120%;
      padding: ${theme.getValue(44)} ${theme.getValue(36)};
    `)}
`;

export const RightColumn = styled.div`
  display: flex;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  box-sizing: border-box;
  padding: ${({ theme }) => theme.getValue(30)};
  display: flex;
  justify-content: center;
  flex-direction: column;
  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:nth-child(2) {
      margin-top: ${({ theme }) => theme.getFontSize(24)};
    }
  }
  p {
    margin: 0;
    color: ${({ theme }) => theme.colors.gray};
    font-size: ${({ theme }) => theme.getFontSize(20)};
    line-height: ${({ theme }) => theme.getFontSize(22)};
    font-weight: 400;
  }
  h2 {
    font-weight: 400;
    margin: 0;
    color: ${({ theme }) => theme.colors.titleBlue};
    font-size: ${({ theme }) => theme.getFontSize(30)};
    line-height: ${({ theme }) => theme.getFontSize(33)};
  }
  ${({ theme }) =>
    onVerticalScreen(css`
      padding: ${theme.getValue(100)} ${theme.getValue(44)};
      & > div {
        &:nth-child(2) {
          margin-top: ${theme.getFontSize(80)};
        }
      }
    `)}
`;
