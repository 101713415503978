import { useEffect, useCallback, useState } from 'react';
import { Route as AppRoute } from '../../types/routes.d';
import { Input } from '../../components/Input';
import { Logo } from '../../components/Logo';
import { ButtonElem, ButtonContainer } from '../../components/Button';
import { Container, Description, ErrorMessage } from './styles';
import usePlayerContext from '../../context/PlayerContext';
import useGameContext from '../../context/GameContext';
import { useForm } from 'react-hook-form';
import { useSearchNavigate } from '../../hooks/useSearchNavigate';
import { useLanguage } from '../../context/LanguageContext';

type RegistrationFormType = {
  playerName: string;
  soeId: string;
};

export function Registration() {
  // const [checked] = useState(true);
  const [pressed, setPressed] = useState(false);
  const [pressedIn, setPressedIn] = useState(false);
  const { createPlayer, player } = usePlayerContext();
  const { createGameSession } = useGameContext();
  const { language } = useLanguage();

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<RegistrationFormType>({
    defaultValues: {
      playerName: '',
      soeId: '',
    },
  });

  const navigate = useSearchNavigate();

  const onSubmit = useCallback(
    (data: RegistrationFormType) => {
      const [firstName, ...lastName] = data.playerName.trim().split(' ');
      createPlayer({
        first_name: firstName,
        last_name: lastName.join(' ') || '',
        soeid: data.soeId,
      });
    },
    [createPlayer],
  );

  const handleCreateGameSession = useCallback(async () => {
    await createGameSession();
    navigate(AppRoute.Countdown);
  }, [createGameSession, navigate]);

  useEffect(() => {
    if (player?.id) {
      handleCreateGameSession();
    }
  }, [player?.id]);

  const hasAllFields = watch('playerName') && watch('soeId');

  return (
    <Container>
      <div>
        <Logo height={70} width={108} />

        <h1>{language.how_to_play}</h1>

        <Description>{language.game_description}</Description>
      </div>
      <div>
        <h2>{language.who_is_playing}</h2>
        <form
          onSubmit={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setPressed(true);
            setPressedIn(true);
            setTimeout(() => {
              setPressedIn(false);
            }, 500);
            setTimeout(() => {
              handleSubmit(onSubmit)(e);
            }, 700);
          }}>
          <div>
            <Input
              error={!!errors.playerName}
              placeholder={language.players_full_name}
              {...register('playerName', { required: true, pattern: /^.+ .+$/ })}
            />
            {!!errors.playerName && (
              <ErrorMessage>{language.invalid_name_and_last_name}</ErrorMessage>
            )}
          </div>
          <Input
            error={!!errors.soeId}
            placeholder={language.soeid}
            {...register('soeId', { required: true })}
          />
          {/* <div className="checkbox-container">
            <input
              type="checkbox"
              checked={checked}
              onChange={(e) => setChecked(e.target.checked)}
              style={{
                marginRight: '8px',
              }}
            />
            <span className="label">
              I have read & accept{' '}
              <a onClick={() => setTermsModalOpen(true)} className="terms-link">
                Terms & Conditions
              </a>
            </span>
          </div> */}
          <ButtonContainer>
            <ButtonElem
              disabled={!hasAllFields}
              className={pressed ? (pressedIn ? 'pressed' : 'notpressed') : ''}
              type="submit">
              {language.ready}
            </ButtonElem>
          </ButtonContainer>
        </form>
      </div>
    </Container>
  );
}
