import { Countdown as CountdownBase } from '../../components/Countdown';
import { useLanguage } from '../../context/LanguageContext';
import { useSearchNavigate } from '../../hooks/useSearchNavigate';
import { Route } from '../../types/routes.d';
import { Container } from './styles';

export const Countdown = () => {
  const navigate = useSearchNavigate();
  const { language } = useLanguage();

  return (
    <Container>
      <CountdownBase onFinish={() => navigate(Route.Question)} />
      <h2>{language.great_youre_all_set}</h2>
      <h1>{language.get_ready}</h1>
    </Container>
  );
};
