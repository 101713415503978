import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  ${({ theme }) => `
    font-family: ${theme.fonts.citiSans};
    color: ${theme.colors.white};
    font-size: ${theme.getValue(40)};
  `}
  & p {
    margin: 0;
  }
  & svg {
    ${({ theme }) => `
      height: ${theme.getValue(40)};
      width: ${theme.getValue(40)};
      margin-right: ${theme.getValue(8)};
    `}
  }
`;
