import { useEffect, useMemo, useRef, useState } from 'react';

import { Container } from './styles';
import { ReactComponent as TimerIcon } from '../../assets/icons/timer2.svg';
// import { ReactComponent as TimerIcon } from '../../assets/icons/timer.svg';

type TimerPropTypes = {
  seconds: number;
  onFinish: () => void;
  restart?: number;
  loading?: boolean;
  setCurrentTime?: (v: number) => void;
};

export const Timer = ({
  seconds,
  onFinish,
  restart = 0,
  loading = false,
  setCurrentTime = () => {},
}: TimerPropTypes) => {
  const [timeLeft, setTimeLeft] = useState<number>(seconds);

  const onFinishRef = useRef(onFinish);

  useEffect(() => {
    setCurrentTime(seconds - timeLeft);
  }, [timeLeft]);

  useEffect(() => {
    if (!loading) setTimeLeft(seconds);
  }, [restart, seconds, setTimeLeft, loading]);

  useEffect(() => {
    if (timeLeft <= 0) {
      onFinishRef?.current();
    } else {
      const timeoutId = setTimeout(() => {
        if (!loading) setTimeLeft(timeLeft - 1);
      }, 1000);

      return () => clearTimeout(timeoutId);
    }
  }, [timeLeft, onFinishRef, setTimeLeft]);

  const timeToRender = useMemo(() => {
    const minutesLeft = Math.trunc(timeLeft / 60);
    const secondsLeft = String(timeLeft % 60).replace(/^(\d)$/, '0$1');

    return `${minutesLeft}:${secondsLeft}`;
  }, [timeLeft]);

  return (
    <Container>
      <TimerIcon />
      <p>{timeToRender}</p>
    </Container>
  );
};
